export default function ({ $axios, app }) {
  $axios.onError(error => {
    let response = error.response;
    let data = response.data;

    let title,
      detail = null;
    let errorKey = data.errorKey;
    if (errorKey != null) {
      title = app.i18n.t('server.' + data.message);
      detail = data.type;
    } else {
      title = data.title;
      if (title == null) {
        title = response.status + ':';
        title += response.statusText;
      }

      detail = data.detail;
      if (data.detail == null) {
        detail = data.errors;
      }
    }

    //switch (error.response.status) {
    // case 401:
    //case 405:
    //case 500:
    app.toast({
      title: title,
      message: detail,
      type: 'error'
    });
    //break;
    //}
  });
}
