import _ from 'lodash';
import { containsCoordinate } from 'ol/extent';
import MultiPolygon from 'ol/geom/MultiPolygon';
import VueNotifications from 'vue-notifications';
import Search from '~/assets/javascript/Search';
import SearchState from '~/assets/javascript/SearchState';
import SearchType from '~/assets/javascript/SearchType';

const state = () => ({
  map: null,
  mode: 'public',
  zoomLevel: null,
  dataExtent: null,
  legends: null,
  currentSearch: null,
  currentSearchState: null,
  baseLayerDefs: null,
  baseLayersLoaded: false,
  capabilitiesLoaded: false,
  searches: [],
  initAmcFeatures: [],
  libFeatures: [],
  canLoadCriteriaLayers: false,
  canSearch: false,
  adminUrl: null,
  version: null
});

const getters = {
  reverseSearches: state => {
    return state.searches.slice().reverse();
  }
};

const mutations = {
  setMap(state, map) {
    state.map = map;
  },
  setMode(state, mode) {
    state.mode = mode;
  },
  setBaseLayerDefs(state, baseLayerDefs) {
    state.baseLayerDefs = baseLayerDefs;
  },
  setZoomLevel(state, zoomLevel) {
    state.zoomLevel = zoomLevel;
  },
  setDataExtent(state, dataExtent) {
    state.dataExtent = dataExtent;
  },
  setLegends(state, legends) {
    state.legends = legends;
  },
  setBaseLayersLoaded(state, baseLayersLoaded) {
    state.baseLayersLoaded = baseLayersLoaded;
  },
  updateSearches(state, searches) {
    state.searches = searches;
  },
  removeSearch(state, search) {
    state.searches = _.filter(state.searches, o => {
      return o.id != search.id;
    });
  },
  newSearch(state) {
    state.currentSearchState = null;
    state.currentSearch = new Search();
  },
  updateCurrentSearch(state, search) {
    state.currentSearch = search;
  },
  updateLibFeatures(state, libFeatures) {
    state.libFeatures = libFeatures;
  },
  addCriterionToCurrentSearch(state, feature) {
    state.currentSearch.addCriterion(feature);
  },
  removeFeatureToCurrentSearch(state, feature) {
    state.currentSearch.remmoveCriterion(feature);
  },
  saveCurrentSearch(state) {
    let index = _.findIndex(state.searches, ['id', state.currentSearch.id]);
    if (index == -1) {
      state.searches.push(state.currentSearch);
    }
  },
  saveCurrentSearchState(state, currentSearchState) {
    state.currentSearchState = currentSearchState;
  },
  setCanLoadCriteriaLayers(state, canLoadCriteriaLayers) {
    state.canLoadCriteriaLayers = canLoadCriteriaLayers;
  },
  setCanSearch(state, canSearch) {
    state.canSearch = canSearch;
  },
  setAdminUrl(state, adminUrl) {
    state.adminUrl = adminUrl;
  },
  setVersion(state, version) {
    state.version = version;
  }
};

const actions = {
  initMap(context, map) {
    context.commit('setMap', map);
    const positionStr = localStorage.getItem('position');
    const view = map.getView();

    if (!_.isEmpty(positionStr)) {
      const position = JSON.parse(positionStr);
      view.animate(position);
    }

    //check zoom level
    map.on('movestart', (e) => {
      const mapZoom = view.getZoomForResolution(e.frameState.viewState.resolution);
      map.once('moveend', () => {
        const currentZoom = view.getZoom();
        const currentCenter = view.getCenter();
        if (currentZoom !== mapZoom) {
          context.dispatch('checkZoomLevel');
        }

        const position = {
          center: currentCenter,
          zoom: currentZoom
        };

        localStorage.setItem('position', JSON.stringify(position));
      });
    });
    context.dispatch('checkZoomLevel');
  },
  checkZoomLevel(context) {
    const map = context.state.map;
    if (!_.isNil(map)) {
      const currentZoom = context.state.map.getView().getZoom();
      const canSearch = currentZoom >= context.state.zoomLevel;
      context.commit('setCanSearch', canSearch);
    }
  },
  updateMode(context, mode) {
    context.commit('setMode', mode);
  },
  getInitAMC(context) {
    return new Promise(resolve => {
      this.$axios
        .get('api/amc/' + context.state.mode + '/feature?module=init_amc&status=OK')
        .then(response => {
          this.dispatch('main/computeLibrary', response.data);
          this.dispatch('main/loadSearches');
          resolve();
        });
    });
  },
  getCapabilities(context) {
    return new Promise(resolve => {
      this.$axios.get('api/capabilities').then(response => {
        context.commit('setBaseLayerDefs', response.data.layers);
        context.commit('setZoomLevel', response.data.zoomLevel);
        context.commit('setDataExtent', response.data.extent);
        context.commit('setAdminUrl', response.data.adminUrl);
        context.commit('setVersion', response.data.version);

        let legends = [];
        response.data.legends.map((legendConfig, index) => {
          let legend = {
            id: legendConfig.color_id,
            title: legendConfig.color_description,
            colors: legendConfig.colors,
            active: index === 0
          };
          legends.push(legend);
        });

        context.commit('setLegends', legends);
        context.dispatch('checkZoomLevel');
        resolve();
      });
    });
  },
  loadSearches(context) {
    let searchesStr = localStorage.getItem('searches');
    if (!_.isEmpty(searchesStr)) {
      let dataSearches = JSON.parse(searchesStr);
      let searches = [];
      dataSearches.forEach(data => {
        let search = new Search(data);
        if (search.allCriterionAvailable(context.state.libFeatures)) {
          if (
            search.type !== SearchType.COVIS ||
            this.app.store.getters['user/isLogged'] === true
          ) {
            searches.push(search);
          }
        }
      });

      context.commit('updateSearches', searches);
    }
  },
  computeLibrary(context, features) {
    features.forEach(feature => {
      feature.criteres = [];
      if (feature.status === 'OK' && feature.resultat.Action) {
        feature.resultat.Action.forEach(action => {
          var critere = {
            id: feature.id + '_' + action.value,
            featureId: feature.id,
            original: feature,
            domaine: feature.domaine,
            nom: feature.nom,
            metadonnees: feature.metadonnees,
            categorie: feature.resultat.categorie,
            ponderation: 3,
            action: action
          };
          feature.criteres.push(critere);
        });
      }
    });
    features = features.filter(feature => {
      return feature.criteres.length > 0;
    });
    features.sort((f1, f2) => {
      return f1.nom.localeCompare(f2.nom);
    });
    context.commit('updateLibFeatures', features);
  },
  addCriterionToCurrentSearch(context, feature) {
    var criterion = _.clone(feature);
    context.commit('addCriterionToCurrentSearch', criterion);
  },
  /**
   * Lance la recherche passée en paramètre sur l'emprise courante de la carte
   * @param {*} context
   * @param {*} search
   */
  launchSearch(context, search) {
    context.state.currentSearch = search;
    context.dispatch('launchCurrentSearch');
  },
  /**
   * Lance la recherche courante sur l'emprise courante de la carte.
   * @param {*} context
   */
  launchCurrentSearch(context) {
    let search = context.state.currentSearch;
    context.commit('saveCurrentSearchState', SearchState.SEARCHING);
    let map = context.state.map;
    let view = map.getView();

    let extent = view.calculateExtent();
    let center = view.getCenter();
    let zoom = view.getZoom();

    search.extent = extent;
    search.position = {
      center: center,
      zoom: zoom
    };

    let calcToSend = null;
    if (search.type === SearchType.AMC) {
      calcToSend = {
        nom: search.name,
        domaine: [],
        parametre: {
          layers: [],
          extent: search.extent
        },
        validite: 0,
        metadonnees: '',
        user_access: 1,
        stats: {
          criteria: []
        }
      };
      search.criteria.map((criterion, index) => {
        let stat = {
          name: criterion.nom,
          action: criterion.action.label,
          ponderation: criterion.ponderation
        };

        var categorySelected = 0;
        if (!_.isNil(criterion.categorySelected)) {
          categorySelected = criterion.categorySelected.value;
          stat.category = criterion.categorySelected.label;
        }
        criterion.exportId = 'R' + criterion.featureId + '_' + categorySelected + '_' + criterion.action.value;
        let layer = {
          name: criterion.exportId,
          weight: criterion.ponderation,
          order: index + 1
        };
        calcToSend.parametre.layers.push(layer);

        calcToSend.stats.criteria.push(stat);
      });
    } else if (search.type === SearchType.COVIS) {
      //calcul de l'extent
      search.toSend.parametre.extent = extent;
      //calcul des points à conserver.
      let pointsToSend = [];
      search.points.map(point => {
        if (containsCoordinate(extent, point.coord)) {
          pointsToSend.push(point);
        }
      });

      if (pointsToSend.length === 0) {
        const message = this.app.i18n.t('covisibility.errors.noPoints');
        this.app.toast({
          title: message,
          type: VueNotifications.types.error,
          timeout: 3000
        });
        context.commit('saveCurrentSearchState', SearchState.ERROR);
        return;
      }

      search.toSend.parametre.pts_obs = pointsToSend;

      calcToSend = search.toSend;
    }

    this.$axios
      .post('api/amc/' + context.state.mode + '/' + search.type, calcToSend)
      .then(response => {
        search.transactionId = response.headers['transaction-id'];
        context.dispatch('checkCalc', {
          response: response.data,
          search: search
        });
      }, () => {
        context.commit('saveCurrentSearchState', SearchState.ERROR);
      }
      );
    context.commit('saveCurrentSearch');
  },
  checkCalc(context, payload) {
    var response = payload.response;
    var search = payload.search;
    this.$axios
      .get('api/amc/' + context.state.mode + '/feature/' + response.id)
      .then(response => {
        let data = response.data[0];
        if (data.status === 'IN_PROCESS') {
          setTimeout(
            function () {
              context.dispatch('checkCalc', {
                response: response.data[0],
                search: search
              });
            }.bind(this),
            1000
          );
        } else if (data.status === 'OK') {
          context.dispatch('searchSuccess', {
            response: data,
            search: search
          });
        } else {
          context.dispatch('searchError', response, search);
          this.$axios.get(
            'api/amc/private/end_calc/' + search.transactionId + '?state=false'
          );
        }
      });
  },
  searchSuccess(context, payload) {
    var response = payload.response;
    var search = payload.search;

    if (response.resultat) {
      search.exportInfo = [];
      response.resultat.layers.map((layer, index) => {
        if (search.type === SearchType.COVIS) {
          if (index === 0) {
            search.name = layer.label;
            search.layerName = layer.value;
            search.exportInfo.push(layer);
            search.originalFeature = response;
          }
        } else {
          let criterion = search.criteria[layer.order - 1];
          if (layer.order == 0) {
            search.layerName = layer.name;
            layer.weight = 1;
          } else {
            criterion.layerName = layer.name;
          }

          if (criterion != null) {
            layer.criterion = Search.prepareCriterionForSerialisation(
              criterion
            );
          }
          search.exportInfo.push(layer);
        }
      });
    }

    if (search.layerName) {
      var prefixUrl = 'api/amc/' + context.state.mode + '/export/';
      var suffixUrl = '?format=PNG';
      if (context.state.currentSearch.legend) {
        suffixUrl += '&color=' + context.state.currentSearch.legend.id;
      }
      if (search.type === SearchType.AMC) {
        suffixUrl += '&histogram=true';
      }
      //On exporte en premier la couche principale
      let url = prefixUrl + search.layerName + suffixUrl;
      this.$axios
        .post(url)
        .then(
          response => {
            context.dispatch('exportSuccess', {
              response: response,
              search: search
            });
          },
          () => {
            this.$axios.get('api/amc/private/end_calc/' + search.transactionId + '?state=false'
            );
          }
        )
        .then(() => {
          if (search.type === SearchType.AMC) {
            //puis on exporte les couches de chaque critères s'il y a plus d'un critère
            let exportOthersLayers = [];
            search.criteria.map(criterion => {
              if (!_.isEmpty(criterion.layerName)) {
                url = prefixUrl + criterion.layerName + suffixUrl + '&generator=true';
                exportOthersLayers.push(this.$axios.post(url));
              }
            });

            Promise.all(exportOthersLayers).then(otherResponses => {
              otherResponses.map(otherResponse => {
                if (otherResponse.status === 200) {
                  const data = otherResponse.data;
                  search.criteria.map(criterion => {
                    if (criterion.layerName === data.id) {
                      criterion.exportFile = data.file;
                      criterion.exportProj = data.proj;
                      criterion.exportExtent = [
                        data.west,
                        data.south,
                        data.east,
                        data.north
                      ];
                    }
                  });
                }
              });
              context.commit('setCanLoadCriteriaLayers', true);
            });
          }
        });
    }
  },
  exportSuccess(context, payload) {
    let search = payload.search;
    this.$axios
      .get('api/amc/private/end_calc/' + search.transactionId)
      .then((endCalcResponse) => {
        context.dispatch('user/setCredit', endCalcResponse.data.availableCredit, { root: true });
        console.log(endCalcResponse);

        const response = payload.response.data;

        search.exportProj = response.proj;
        search.exportExtent = [
          response.west,
          response.south,
          response.east,
          response.north
        ];
        search.exportFile = response.file;

        context.commit('saveCurrentSearchState', SearchState.SUCCESS);
      });
  },
  searchError(context, response) {
    let data = response.data[0];

    let message = data.status_error;

    const split = data.status_error.split(';');
    const i18nKey = 'server.' + split[0];
    let args = {};
    if (split.length > 1) {
      let i18nArgs = split[1];
      i18nArgs = i18nArgs.split(',');

      for (let i = 0; i < i18nArgs.length; i++) {
        args[i] = i18nArgs[i];
      }
    }
    message = this.app.i18n.t(i18nKey, args);
    console.error(message);
    this.app.toast({
      title: message,
      type: VueNotifications.types.error,
      timeout: 3000
    });
    context.commit('saveCurrentSearchState', SearchState.ERROR);
  },
  loadResultStart(context) {
    context.commit('saveCurrentSearchState', SearchState.LOADING);
  },
  loadResultEnd(context) {
    context.commit('saveCurrentSearchState', SearchState.LOADING_SUCCESS);
    this.commit('panel/closeMainPanel');
    this.commit('panel/closeSecondaryPanel');
    this.commit('panel/openHistoryPanel');

    let searches = [];
    context.state.searches.forEach(aSearch => {
      searches.push(aSearch.prepareForSerialisation());
    });

    searches = JSON.stringify(searches);
    localStorage.setItem('searches', searches);
  },
  loadResultError(context) {
    context.commit('saveCurrentSearchState', SearchState.LOADING_ERROR);
  },
  removeSearch(context, search) {
    let index = _.findIndex(context.state.searches, ['id', search.id]);

    if (index != -1) {
      context.commit('removeSearch', search);
      let searches = [];
      context.state.searches.forEach(aSearch => {
        searches.push(aSearch.prepareForSerialisation());
      });
      searches = JSON.stringify(searches);
      localStorage.setItem('searches', searches);
    }
  },
  cloneSearch(context, search) {
    let clone = search.prepareForSerialisation();
    clone.name = '';
    clone.id = Date.now();

    let newSearch = new Search(clone);
    context.commit('updateCurrentSearch', newSearch);
  },
  centerOnMap(context, coordinates) {
    let commune = new MultiPolygon([coordinates]);
    commune.transform('EPSG:4326', 'EPSG:2154');
    context.state.map.getView().fit(commune);
  }
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
};
