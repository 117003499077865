import _ from 'lodash';
import SearchType from './SearchType';

class Search {
  constructor(data) {
    if (_.isNil(data)) {
      this.id = Date.now();
      this.position = {};
      this.opacity = 50;
      this.criteria = [];
    } else {
      _.merge(this, data);
    }
    if (_.isNil(this.type)) {
      this.type = SearchType.AMC;
    }
  }

  addCriterion(feature) {
    this.criteria.push(feature);
  }
  remmoveCriterion(feature) {
    var index = this.criteria.indexOf(feature);
    if (index != -1) {
      this.criteria.splice(index, 1);
    }
  }
  prepareForSerialisation() {
    let clone = _.pick(
      this,
      'id',
      'name',
      'type',
      'criteria',
      'exportExtent',
      'extent',
      'opacity',
      'position',
      'legend',
      'toSend',
      'points'
    );

    let criteria = [];
    clone.criteria.map(criterion => {
      let cloneCriterion = Search.prepareCriterionForSerialisation(criterion);
      criteria.push(cloneCriterion);
    });
    clone.criteria = criteria;

    clone.criteria.forEach(criterion => {
      delete criterion.original;
    });
    return clone;
  }

  allCriterionAvailable(availableFeatures) {
    let result = true;
    this.criteria.map(criterion => {
      let criterionAvailable = false;
      availableFeatures.map(availableFeature => {
        if (criterion.featureId === availableFeature.id) {
          criterionAvailable = true;
        }
      });
      if (criterionAvailable == false) {
        result = false;
      }
    });
    return result;
  }

  static prepareCriterionForSerialisation(criterion) {
    return _.pick(
      criterion,
      'id',
      'categorie',
      'categorySelected',
      'domaine',
      'featureId',
      'metadonnee',
      'nom',
      'ponderation',
      'action'
    );
  }
}

export default Search;
