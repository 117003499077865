const state = () => ({
  mainPanelOpen: true,
  secondaryPanelOpen: false,
  historyPanelOpen: false,
  searchPanelOpen: false,
  searchLibPanelOpen: false,
  libPanelOpen: false,
  showSearchPlaceModal: true
});

const mutations = {
  openMainPanel(state) {
    state.mainPanelOpen = true;
  },
  closeMainPanel(state) {
    state.mainPanelOpen = false;
  },
  openSecondaryPanel(state) {
    if (this.app.store.getters['user/isLogged']) {
      state.secondaryPanelOpen = true;
    }
  },
  closeSecondaryPanel(state) {
    state.secondaryPanelOpen = false;
  },
  openSearchPanel(state) {
    state.searchPanelOpen = true;
  },
  closeSearchPanel(state) {
    state.searchPanelOpen = false;
  },
  openSearchLibPanel(state) {
    state.searchLibPanelOpen = true;
  },
  closeSearchLibPanel(state) {
    state.searchLibPanelOpen = false;
  },
  openLibPanel(state) {
    state.libPanelOpen = true;
  },
  closeLibPanel(state) {
    state.libPanelOpen = false;
  },
  openSearchPanels(state) {
    state.searchPanelOpen = true;
    state.libPanelOpen = true;
  },
  closeSearchPanels(state) {
    state.searchPanelOpen = false;
    state.libPanelOpen = false;
  },
  openHistoryPanel(state) {
    state.historyPanelOpen = true;
  },
  closeHistoryPanel(state) {
    state.historyPanelOpen = false;
  },
  showSearchPlaceModal(state) {
    state.showSearchPlaceModal = true;
  },
  hideSearchPlaceModal(state) {
    state.showSearchPlaceModal = false;
  }
};

export default {
  namespaced: true,
  state,
  mutations
};
