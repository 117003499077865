import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import iziToast from 'izitoast';

import 'izitoast/dist/css/iziToast.min.css';

function toast({ title, message, type, timeout }) {
  if (type === VueNotifications.types.warn) type = 'warning';
  if (timeout == undefined) timeout = 5000;
  if (message == undefined) message = '';
  return iziToast[type]({
    title,
    message,
    timeout
  });
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};

Vue.use(VueNotifications, options);

export default ({ app }) => {
  app.toast = toast;
};
