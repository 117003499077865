import _ from 'lodash';
import VueNotifications from 'vue-notifications';
import SessionStorage from '~/assets/javascript/SessionStorage';

const state = () => ({
  token: null,
  login: null,
  info: null
});

const mutations = {
  setToken(state, token) {
    state.token = token;
  },
  setLogin(state, login) {
    state.login = login;
  },
  setInfo(state, info) {
    state.info = info;
  },
  setCredit(state, credit) {
    state.info.credit = credit;
  }
};

const getters = {
  isLogged: state => {
    return !_.isNil(state.token);
  }
};

const actions = {
  tryLoginOnConnexion() {
    let token = sessionStorage.getItem(SessionStorage.authToken);
    if (_.isNil(token)) {
      const queryParams = window.$nuxt._route.query;
      if (queryParams[SessionStorage.authToken]) {
        token = queryParams[SessionStorage.authToken];
        sessionStorage.setItem(SessionStorage.authToken, token);
        this.$router.push({ query: {} });
      }
    }

    if (!_.isNil(token)) {
      this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      this.$axios.get('api/account').then(response => {
        this.dispatch('user/login', {
          token: token,
          login: response.data.login
        });
        let successTitle = this.app.i18n.t('user.authentication.success.title');
        this.app.toast({
          title: successTitle,
          type: VueNotifications.types.success,
          timeout: 3000
        });
      });
    }
  },
  login(context, info) {
    this.$axios.defaults.headers.common['Authorization'] =
      'Bearer ' + info.token;

    context.commit('setToken', info.token);
    context.commit('setLogin', info.login);
    context.dispatch('getAccountInfo');
    context.dispatch('main/updateMode', 'private', { root: true });
    context.dispatch('main/getCapabilities', {}, { root: true });
    context.dispatch('main/getInitAMC', {}, { root: true });
    this.commit('panel/openSecondaryPanel');
    document.body.classList.add('connected');
  },
  logout(context) {
    delete this.$axios.defaults.headers.common['Authorization'];
    context.commit('setToken', null);
    context.commit('setLogin', null);
    context.dispatch('main/updateMode', 'public', {
      root: true
    });
    context.dispatch(
      'main/getCapabilities',
      {},
      {
        root: true
      }
    );
    context.dispatch(
      'main/getInitAMC',
      {},
      {
        root: true
      }
    );
    this.commit('panel/closeSecondaryPanel');
    document.body.classList.remove('connected');
  },
  setCredit(context, credit) {
    context.commit('setCredit', credit);
  },
  getAccountInfo(context) {
    if (context.getters.isLogged) {
      this.$axios.get('api/amc/private/accountInfo').then(response => {
        context.commit('setInfo', response.data);
      });
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
