import Vuex from 'vuex';

import main from './main';
import panel from './panel';
import user from './user';

export default () => {
  return new Vuex.Store({
    modules: {
      main: main,
      panel: panel,
      user: user
    }
  });
};
