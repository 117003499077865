import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

export default ({ app, store }) => {
  app.i18n = new VueI18n({
    locale: store.state.locale,
    silentTranslationWarn: true,
    fallbackLocale: 'fr'
  });

  app.i18n.path = link => {
    if (app.i18n.locale === app.i18n.fallbackLocale) {
      return `/${link}`;
    }

    return `/${app.i18n.locale}/${link}`;
  };

  app.$axios.get('i18n/fr.json').then(res => {
    app.i18n.setLocaleMessage('fr', res.data);
  });
};
